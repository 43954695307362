<template>
	<div class="empty_components">
		<img src="../assets/img/empty_placeholder.png"/>
		<div class="">{{text}}</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				
			}
		},
		props:{
			text:{
				type:String,
				default:'暂无数据'
			}
		},
	}
</script>

<style lang="scss" scoped>
	.empty_components{
        margin: 40px 0;
		>img{
			width: 375px;
			height: 182px;
			display: block;
			margin: 0 auto;
		}
		>div{
			padding: 20px;
			text-align: center;
			color: #999999;
			font-size: 20px;
		}
	}
</style>
