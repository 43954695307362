<template>
    <div class="infomation-list">
        <div class="swiper-container pageTop_box" id="infomationListTop_swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,i) in banner" :key="i">
                    <div class="bg_img" :style="'background-image: url('+item+')'"></div>
                </div>
            </div>
            <div class="pagination infomationListTop-pagination"></div>
        </div>
        
        <div class="list_box">
            <div class="tab_list scrollbar_box_x">
                <div :class="{'active':selectTabId==item.id}"
                v-for="item in tab_list" :key="item.id" @click="onSelectTab(item.id, item.name)">
                    {{item.name}}
                </div>
            </div>
            <div class="line"></div>
            <div class="list_" ref="infomation5_info">
                <template  v-if="articles_list.length>0">
                    <div v-for="item in articles_list" :key="item.id" >
                        <div class="detail_b" @click="onInfoDetail(item.id)">
                            <div class="img_box"  :style="'background-image: url('+item.thumbnail+')'"></div>
                            <div class="content_">
                                <div class="name ellipsis">{{item.title}}</div>
                                <div class="info">{{item.excerpt || item.content|edit_content}}</div>
                                <div class="bottom_">
                                    <div class="floatLf time">
                                        <img src="../assets/img/timer.png" >
                                        <span>{{item.time|set_time}}</span>
                                    </div>
                                    <div class="floatRt detail">查看详情></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <empty-icon style="height:auto" v-else></empty-icon>
            </div>

            <gt-pagination className="center" :total="total" :currentPage="currentPage" :pageSize="pageSize" @updatePagination="pagination_event"></gt-pagination>
        </div>
        <footerComponent ></footerComponent>
    </div>
</template>
<script>
import gtPagination from '@/components/gt-pagination'
import emptyIcon  from '@/components/empty'
export default {
    components:{gtPagination, emptyIcon},
    name:'infomationList',
    data(){
        return{
            selectTabId:0,
            selectTabName:'',

            total:0,
            currentPage:1,
            pageSize: 6,
            
            banner:[],
            tab_list:[],
            articles_list:[]
        }
    },
    watch:{
        selectTabId:{
            handler(val){
               let el = this.$refs.infomation5_info;
                el.classList.add('active')
                setTimeout(() => {
                    el.classList.remove('active')
                }, 500);
            },
        },
    },
    created(){
        this.initData();
        let query = this.$route.query||{};
        this.selectTabId = query.id;
        this.selectTabName = query.name;
        this.getList()
    },
    methods:{
         initData(){
            this.$Request({
                url: '/portal/news_information',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                //banner
                this.banner = this.$getListByObj(data.news).map(item=>{return item.image});
                if(this.banner.length>1){
                    this.$nextTick(()=>{
                        new Swiper ('#infomationListTop_swiper', {
                            pagination: '.infomationListTop-pagination',
                            paginationClickable :true,
                            autoplay: 5000,
                        })
                    })
                }
            })
        },
        getList(){
            this.$Request({
                url: `/portal/get_cate_list/${this.selectTabId}/${this.currentPage}`,
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                let has = data.cateList.filter(item=>{
                    return item.id == this.selectTabId
                })[0]
                if(!has){
                    this.tab_list = [{id: this.selectTabId, name: this.selectTabName }]
                }else{
                    this.tab_list = data.cateList;
                }
                if(this.selectTabName=='专家智库'){
                    this.articles_list = data.articles.map(item=>{
                        if(item.img)item.thumbnail = item.img
                        return item
                    });
                }else{
                    this.articles_list = data.articles;
                }
                this.pageSize = data.pageSize;
                this.total= data.totalCount||1;
            })
        },
        onSelectTab(id, name){
            if(this.selectTabId != id){
                this.selectTabId = id;
                this.selectTabName = name;
                this.getList()
            }
        },
        onInfoDetail(id){
            this.$router.push({name:'infomationDetail', query:{id: id}})
        },
        pagination_event(val){
            this.$nextTick(() => {
                this.currentPage = val.currentPage
                this.pageSize = val.pageSize;
                this.getList()
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.infomation-list{
    .pageTop_box{
        width: 100%;
        height: 450px;
    }
    .list_box{
       width: 1200px;
       margin: 0 auto; 
       padding: 80px 0;
    }
    .tab_list{
        white-space: nowrap;
        overflow-x: auto;
        >div{
            margin-bottom: 20px;
            display: inline-block;
            height: 64px;
            line-height: 64px;
            min-width: 180px;
            padding: 0 6px;
            background: #F5F5F5;
            color: #999999;
            font-size: 24px;
            text-align: center;
            cursor: pointer;
            &:not(:nth-child(6n)){
                margin-right: 24px;
            }
            &:hover{
                color: $lv;
            }
            &.active{
                background-color: $lv;
                color: #fff;
            }
        }
    }
    .line{
        width: 100%;
        height: 1px;
        background-color: #e6e6e6;
    }
    .list_{
        border-bottom: 1px solid#e6e6e6;
        &.active{
            animation: slideInLeft 0.5s linear forwards;
        }
        >div{
            width: 100%;
            height: 220px;
            background-color: #fff;
            overflow: hidden;
            border-top: 1px solid#e6e6e6;
            transition: all .2s ease;
            // margin-bottom: 4px;
            cursor: pointer;
            &:first-of-type{
                border-top-width: 0;
            }
            .detail_b{
                padding: 20px 30px 20px 10px;
                transition: all .2s ease;
                position: relative;
            }
            .img_box{
               width: 240px;
               height: 180px; 
                @include backImg('../assets/img/home_6_1.png');
                background-color: #ccc;
               display: inline-block;
               vertical-align: middle;
            }
            .content_{
               display: inline-block;
               vertical-align: middle;
               width: calc(100% - 240px);
               padding : 0 50px;

               .name{
                    font-size: 18px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
               }
               .info{
                   margin-top: 14px;
                    font-size: 16px;
                    color: #999999;
                    line-height: 24px;
                    @include textOver(2)
               }
               .bottom_{
                   margin-top: 25px;
                   display: inline-block;
                   width: 100%;
                   color: #999999;
                   font-size: 14px;
                }
                .time{
                    img{
                        width: 16px;
                        height: 16px;
                        vertical-align: middle;
                        margin-right: 8px;
                    }
                    span{
                        vertical-align: middle;
                    }
                }
            }
            &:hover{
                border-top-color: rgba(166,167,175,0);
                box-shadow: 0 2px 15px 0 rgba(0,72,174,.15);
                .detail_b{
                    transform: translate(20px)
                }
                .content_{
                    .name{
                        color: $lv;
                    }
                    .detail{color: $lv;}
                }
            }
        }
    }
}
</style>